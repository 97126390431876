$basic-color: #941922;
$secondary-color: #B4B5D9;
$third-clor: #EF7D30;
$text-color: #333;

@import "bootstrap";
@import "../fonts/Aeroport-black";
@import 'menu';
@import "modal";
@import "animation";
@import "video";
@import "form";
@import "manager";
@import "viewer";

.hidden {
  display: none;
}

body {
  background-color: white !important;
  font-family: 'AeroportBlack', Montserrat, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif !important;
}

a {
  text-decoration: none;
}

iframe {
  border: 0px;
  width: 100%;
  height: 100%;
  position: absolute;
}

.wrapper {
  transition: all 1s ease;

  &__hidden {
    display: none;
  }
}

.backdrop {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000000d1;
  z-index: 50;
  transition: all 1s ease;

  &__hidden {
    display: none;
  }
}

.background {
  &__wrapper {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }

  &__video {
    width: 100%;
    z-index: 0;
    position: absolute;
    transition: all 1s ease;

    &-hidden {
      opacity: 0;
    }

    &-wrapper {
      overflow: hidden;
      max-height: 60vh;
      width: 100%;
      background: white;
      position: relative;
      height: 31vh;
      display: flex;
      align-items: center;
    }
  }

  &__top,
  &__bottom {
    width: 100%;
    text-align: center;
    height: 20vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.logo {
  background-image: url('../images/logo.svg');
  background-repeat: no-repeat;
  background-size: 100%;
  width: 800px;
  height: 130px;
}

.pdf__wrapper {
  width: 100%;
  height: 100%;
}