$color-start: lighten($secondary-color, 22%);
$color-end: lighten($secondary-color, 17%);

.screen-animation {
  animation:slide 3s ease-in-out infinite alternate;
  background-image: linear-gradient(-60deg, $color-start 50%, $color-end 50%);
  bottom:0;
  left:-50%;
  opacity:.5;
  position:fixed;
  right:-50%;
  top:0;
  z-index:-1;

  &__two {
    animation-direction:alternate-reverse;
    animation-duration:4s;
  }

  &__three {
    animation-duration:5s;
  }

}

@keyframes slide {
  0% {
    transform:translateX(-25%);
  }
  100% {
    transform:translateX(25%);
  }
}