/* Menu animation */
.menu-anim-enter {
  opacity: 0 !important;
  transform: scale(0.9);
}
.menu-anim-enter-done {
  opacity: 1 !important;
}
.menu-anim-enter-active {
  opacity: 1 !important;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.menu-anim-exit {
  opacity: 1 !important;
}
.menu-anim-exit-done {
  opacity: 0 !important;
  display: none;
}
.menu-anim-exit-active {
  opacity: 0 !important;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}
