.menu {
  z-index: 100;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  top: 100%;

  &-top {
    &__wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;

      .logo {
        margin-top: 100px;
        margin-bottom: 20px;
      }
    }

    &__subtitle {
      color: $secondary-color;
      font-size: 50px;
      margin-bottom: 50px;
    }
  }

  &__show {
    animation: 0.7s ease 0s 1 contentShowAnimation;
    opacity: 1;
    top: 0;
  }

  &__hide {
    animation: 0.7s ease 0s 1 contentHideAnimation;
    opacity: 0;
    top: 100%;
  }

  &__field {
    z-index: 50;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }

  &__wrapper {
    height: 100%;

    &-inner {
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background-color: white;
      text-align: center;
      background-image: url('../images/menu_bg.svg');
      background-size: 100% 500px;
      background-position: 0 105%;
      background-repeat: no-repeat;
    }
  }

  &-list {
    &__wrapper {
      margin: 0;
      padding: 0;
      width: 95%;
    }

    &__item {
      list-style-type: none;
      width: 33.33%;
      float: left;
      margin-bottom: 20px;
      background-color: white;

      &-wrapper {
        margin: 15px;
        margin-bottom: 0px;
        padding: 40px 20px;
        height: 360px;
        box-shadow: 0px 10px 10px rgb(0 0 0 / 5%);
        border: 1px solid #ddd;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;

        &--compact {
          height: 310px;

          .menu-list__item-img {
            height: 140px;
          }
        }
      }

      &-img {
        max-width: 180px;
        height: 180px;
        margin-bottom: 30px;
      }

      &-caption {
        font-size: 23px;
        line-height: 30px;
        font-weight: 600;
        color: $text-color;
        display: flex;
        align-items: center;
      }
    }
  }
}

//Keyframes animation

@keyframes contentShowAnimation {
  0% {
    opacity: 0;
    top: 100%;
  }
  100% {
    opacity: 1;
    top: 0;
  }
}

@keyframes contentHideAnimation {
  0% {
    opacity: 1;
    top: 0;
  }
  100% {
    opacity: 0;
    top: 100%;
  }
}

@keyframes menuShowItemAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}