.image-viewer {
  height: 100%;
  width: 100%;
  background-image: url('../images/moscow.jpg');
  background-size: auto 100%;
  background-position: 50% 50%;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #191e22;
    opacity: .95;
    z-index: 0;
  }

  &__wrapper {
    padding: 100px;
  }
}