.video-player {
  width: 100%;
  margin-bottom: 20px;

  &__wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    background-image: url('../images/moscow.jpg');
    background-size: auto 100%;
    background-position: 50% 50%;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #191e22;
      opacity: .95;
      z-index: 0;
    }
  }

  &__container {
    position: relative;
    z-index: 100;
    padding: 0;
    width: 100%;
  }

  &__caption {
    font-size: 40px;
    color: white;
    text-align: center;
  }
}