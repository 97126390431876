.cmodal {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  animation: 0.33s ease 0s 1 contentModalHideAnimation;
  z-index: 1000;

  &__show {
    top: 0;
    animation: 0.3s ease 0s 1 contentModalShowAnimation;
  }

  &-body {

    &:before {
      content: " ";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #000000d1;
      z-index: 300;
      transition: all 1s ease;
    }

    &__close-btn {
      display: flex;
      position: absolute;
      top: 45%;
      z-index: 900;
      bottom: 20px;
      left: -70px;
      padding: 15px;
      transition: all .5s ease;
      height: 0;
      width: 200px;
      transform: rotate(90deg);
      border-bottom: 40px solid white;
      border-left: 30px solid transparent;
      border-right: 30px solid transparent;

      svg {
        height: 20px;
        width: 20px;
        margin: 0 auto;
        margin-top: 25px;
      }

      path {
        fill: $basic-color;
      }

      &:hover {
        border-bottom: 40px solid $basic-color;

        path {
          fill: white;
        }
      }
    }

    &__content {
      position: absolute;
      background-color: white;
      width: calc(100%);
      height: calc(100%);
      top: 0;
      left: 0;
      z-index: 500;
    }
  }
}

//Keyframes animation
@keyframes contentModalShowAnimation {
  from {
    top: 100%;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

@keyframes contentModalHideAnimation {
  from {
    top: 0;
    opacity: 1;
  }
  to {
    top: 100%;
    opacity: 0;
  }
}
