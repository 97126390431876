.custom-form {
  position: relative;
  z-index: 100;

  &__wrapper {
    width: 100%;
    height: 100vh;
    font-size: 40px;
    background-image: url('../images/menu_bg.svg');
    background-size: 100% 500px;
    background-position: 0 105%;
    background-repeat: no-repeat;
    background-color: lighten($secondary-color, 20%);
    position: absolute;
    top: 0;
  }

  &__container {
    margin: 100px;
    position: relative;
  }

  &__logo {
    margin: 0 auto;
    margin-bottom: 30px;

    &-subtitle {
      text-align: center;
    }
  }

  &__label {
    font-weight: 600;
    font-size: 40px;
  }

  &__group {
    margin-bottom: 20px;
    position: relative;
  }

  &__input {
    font-size: 40px !important;
    height: 100px;
    padding: 10px 30px !important;
  }

  &__text {
    font-size: 40px !important;
    padding: 10px 30px !important;
  }

  &__error {
    font-size: 35px !important;
    position: absolute;
    z-index: 10;
    right: 0;
    width: auto !important;
    background-color: white;
  }

  &__btn {
    font-size: 40px !important;
    width: 100%;
    height: 100px;
  }

  &-alert {
    &__wrapper {
      background-color: white;
      border: 1px solid #3ABA20;
      box-shadow: 0px 50px 50px rgba(#3ABA20, 0.1);
      padding: 100px;
      position: absolute;
      top: 300px;
      opacity: 0;
      z-index: 50;
    }

    &__icon {
      background-image: url('../images/icons/ico_success.svg');
      width: 200px;
      height: 200px;
      margin: 0 auto;
      margin-bottom: 100px;
    }

    &__text {
      text-align: center;

      h2 {
        font-size: 100px;
        margin-bottom: 70px;
      }

      p {
        font-size: 60px;
      }
    }
  }
}