.manager {
  &__wrapper {
    margin: 100px;
    font-size: 35px;

  }

  &__title {
    font-size: 60px;
    margin-bottom: 50px;
  }

  &__archive {
    &-wrapper {
      overflow-y: auto;
      height: 700px;
      margin-bottom: 50px;
      border: 1px solid #aaa;
    }
  }
}